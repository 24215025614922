import { currencyformatter, theme } from 'src/utils';
import { Invoice, InvoiceItem } from 'src/types';
import {
  getTotals,
  tableHeaders,
} from 'src/pages/admin/PatientDetails/Invoice/constants';
import {
  TR,
  TD,
  TableContent,
} from 'src/pages/admin/PatientDetails/Invoice/styled';
import { Button, Column, Text } from 'src/components';
import ReactTooltip from 'react-tooltip';
import { useStaff } from 'src/state';
import mixpanel from 'mixpanel-browser';
import { decodeHtmlEntities } from 'src/utils/decodeHtmlEntities';
import { WrappedText } from '../styled';

const getOOPAmount = (items: InvoiceItem[]) =>
  items?.reduce((acc, item) => {
    if (item.isHMOPayable === 'OOP' || item.isHMOPayable === false)
      acc += item.totalCost || 0;
    return acc;
  }, 0);

const getHMOAmount = (items: InvoiceItem[]) =>
  items?.reduce((acc, item) => {
    if (item.isHMOPayable === 'HMO' || item.isHMOPayable === true)
      acc += item.totalCost || 0;
    return acc;
  }, 0);

const getReturned = (items: InvoiceItem[]) =>
  items?.reduce((acc, item) => {
    if (item.returned?.length) {
      item.returned.map((itm) => {
        acc.push({
          ...item,
          units: itm.quantity,
          totalCost: (item.unitCost || 0) * itm.quantity,
          reason: itm.reason,
          returnedQty: itm.quantity,
        });
        return itm;
      });
    }
    return acc;
  }, [] as InvoiceItem[]);

const SummaryTable: React.FC<{
  currentInvoice: Invoice;
  handleRowClick?: (item: InvoiceItem) => void;
}> = ({ currentInvoice, handleRowClick }) => {
  const { hasPermission } = useStaff();
  const sums = {
    hmoAmount: getHMOAmount([...currentInvoice?.items]),
    oopAmount: getOOPAmount([...currentInvoice?.items]),
  };
  const invoice: Invoice = {
    ...currentInvoice,
    ...sums,
  };
  const totals = getTotals(invoice as Invoice);
  const getHeaders = () => {
    const headers = [...tableHeaders];
    if (handleRowClick && hasPermission('RETURN_PRODUCT_TO_INVENTORY'))
      headers.push('');
    return headers;
  };

  return (
    <TableContent>
      <Column gap={0.3}>
        <table>
          <thead>
            <TR>
              {getHeaders().map((title, i) => (
                <th key={i}>{title}</th>
              ))}
            </TR>
          </thead>
          <tbody>
            {currentInvoice?.items
              ?.filter((itm) => itm?.units !== 0)
              .map(
                (
                  { units, unitCost, isHMOPayable, description, totalCost },
                  i,
                ) => (
                  <TR key={i}>
                    <TD>
                      <WrappedText>{decodeHtmlEntities(description)}</WrappedText>
                    </TD>
                    <TD>{units}</TD>
                    <TD>{isHMOPayable === 'HMO' ? 'HMO' : 'Out-of-pocket'}</TD>
                    <TD>
                      {currencyformatter.format(
                        parseInt(Number(unitCost).toString(), 10),
                      )}
                    </TD>
                    <TD>
                      {currencyformatter.format(
                        parseInt(Number(totalCost).toString(), 10),
                      )}
                    </TD>
                    {handleRowClick &&
                      hasPermission('RETURN_PRODUCT_TO_INVENTORY') &&
                      (units as number) > 0 && (
                        <TD>
                          <Button
                            variant="text"
                            color={theme.secondary[700]}
                            background={theme.secondary[700]}
                            modStyles={{ pa: 0 }}
                            onClick={() => {
                              mixpanel.track(`Click 'Return & Refund' button`, {
                                feature: 'Inventory Invoice',
                              });
                              handleRowClick(currentInvoice.items[i]);
                            }}
                          >
                            Return & Refund
                          </Button>
                        </TD>
                      )}
                  </TR>
                ),
              )}
            {totals?.map(({ summary, total }, i) => {
              return (
                <TR key={i} noBorder>
                  <TD colSpan={4} size="base" weight="bold">
                    {summary}
                  </TD>
                  <TD size="base" weight="bold">
                    {currencyformatter.format(
                      parseInt(Number(total)?.toString(), 10),
                    )}
                  </TD>
                </TR>
              );
            })}
          </tbody>
        </table>
        {getReturned(invoice.items).length > 0 && (
          <Column gap={0.3} modStyles={{ mt: 1 }}>
            <Text size="sm" weight="semibold">
              Return & Refunds
            </Text>
            <table>
              <thead>
                <TR>
                  {getHeaders().map((title, i) => (
                    <th key={i}>{title}</th>
                  ))}
                </TR>
              </thead>
              <tbody>
                {getReturned(invoice.items).map(
                  (
                    {
                      units,
                      unitCost,
                      isHMOPayable,
                      description,
                      totalCost,
                      reason,
                      returnedQty,
                    },
                    i,
                  ) => (
                    <TR key={i}>
                      <TD>
                        <WrappedText>{decodeHtmlEntities(description)}</WrappedText>
                      </TD>
                      <TD>{units}</TD>
                      <TD>
                        {isHMOPayable === 'HMO' ? 'HMO' : 'Out-of-pocket'}
                      </TD>
                      <TD>
                        {currencyformatter.format(
                          parseInt(Number(unitCost).toString(), 10),
                        )}
                      </TD>
                      <TD>
                        {currencyformatter.format(
                          parseInt(Number(totalCost).toString(), 10),
                        )}
                      </TD>
                      <TD>
                        <Text
                          data-tip
                          data-for="returned-tooltip"
                          weight="semibold"
                          size="sm"
                          color="#01A539"
                        >
                          Returned
                        </Text>
                        <ReactTooltip
                          id="returned-tooltip"
                          place="right"
                          backgroundColor="#FFFFFF"
                        >
                          <Column>
                            <Text size="xs" weight="bold" color="#666666">
                              Quantity: {returnedQty}
                            </Text>
                            <Text size="xs" weight="semibold" color="#888888">
                              Reason: {reason}
                            </Text>
                          </Column>
                        </ReactTooltip>
                      </TD>
                    </TR>
                  ),
                )}
              </tbody>
            </table>
          </Column>
        )}
      </Column>
    </TableContent>
  );
};

export default SummaryTable;
