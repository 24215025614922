import { gql } from '@apollo/client';
import { invoiceFieldFragment } from '../Fragments/invoice';

export const GET_INVOICES = gql`
  query GetInvoices($input: GetInvoicesQuery) {
    getInvoices(input: $input) {
      total
      invoices {
        _id
        invoiceId
        items {
          _id
          currency
          description
          isHMOPayable
          serviceCode
          productCode
          batchNo
          unitCost
          units
          createdFrom
          service {
            id: _id
            isHMOPayable
            description
            serviceCode
          }
          addedBy {
            id: _id
            user {
              firstName
              lastName
            }
          }
        }
        hmoAmount
        note
        oopAmount
        paymentMethod
        status
        markedBy {
          user {
            firstName
            lastName
          }
        }
        invoiceType
        createdAt
        updatedAt
        patient {
          user {
            firstName
            lastName
          }
        }
        markedAt
      }
    }
  }
`;

export const GET_INVOICES_TOTAL = gql`
  query GetInvoices($input: GetInvoicesQuery) {
    getInvoices(input: $input) {
      total
    }
  }
`;

export const GET_INVOICE = gql`
  query GetInvoice($invoiceId: String!) {
    getInvoice(invoiceId: $invoiceId) {
      _id
      invoiceId
      hmo {
        _id
        name
        hmoId
      }
      hmoAmount
      items {
        _id
        currency
        description
        isHMOPayable
        serviceCode
        productCode
        batchNo
        unitCost
        units
        service {
          id: _id
          isHMOPayable
          description
          serviceCode
        }
        returned {
          quantity
          reason
        }
      }
      note
      oopAmount
      patient {
        _id
        status
        user {
          firstName
          lastName
        }
        otherInfo {
          hmo {
            hmo {
              hmoId
              name
            }
          }
        }
      }
      paymentMethod
      status
      createdAt
      invoiceType
      markedBy {
        user {
          firstName
          lastName
        }
      }
      requestedBy {
        _id
        user {
          firstName
          lastName
        }
      }
      updatedAt
      markedAt
    }
  }
`;

export const GET_PATIENT_OPEN_INVOICE = gql`
  query GetOpenInvoice($patientId: String!) {
    getOpenInvoice(patientId: $patientId) {
      ...InvoiceFields
    }
  }
  ${invoiceFieldFragment}
`;

export const GET_INVOICE_LOGS = gql`
  query GetInvoiceLogs($input: GetInvoiceLogsInput!) {
    getInvoiceLogs(input: $input) {
      total
      logs {
        id: _id
        action
        actionBy {
          user {
            firstName
            lastName
          }
          id: _id
        }
        updatedAt
        createdAt
        deletedAt
        ... on AddInvoiceItem {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          updatedAt
          createdAt
          deletedAt
          quantity
          invoiceItem {
            serviceCode
            description
            units
          }
        }
        ... on DeleteInvoiceItem {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          invoiceItem {
            serviceCode
            description
            units
          }
          updatedAt
          createdAt
          deletedAt
          serviceCode
          productCode
          description
        }
        ... on MarkInvoicePaid {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          updatedAt
          createdAt
          deletedAt
        }
        ... on PushToInvoice {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          updatedAt
          createdAt
          deletedAt
          invoiceItems {
            batchNo
            description
            productCode
            units
          }
        }
        ... on UpdateInvoiceItem {
          id: _id
          action
          actionBy {
            user {
              firstName
              lastName
            }
            id: _id
          }
          updatedAt
          createdAt
          deletedAt
          updates {
            after
            before
            field
          }
          invoiceItem {
            serviceCode
            description
          }
        }
      }
    }
  }
`;
