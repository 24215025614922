export const decodeHtmlEntities = (str: string) => {
  const entities: Record<string, string> = {
    '&amp;': '&',
    '&lt;': '<',
    '&gt;': '>',
    '&quot;': '"',
    '&#39;': "'",
  };

  return (str || '').replace(/&[a-zA-Z0-9#]+;/g, (match) => entities[match] || match);
};
