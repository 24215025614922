export const generateAvatar = (name: string, size?: number) => {
  const names = (name || `Jane doe`).trim().split(' ');

  const avatarSize = '100%';
  const avatarBackgroundColor = 'grey';
  const textColor = 'white';

  const svgContent = `
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${avatarSize} ${avatarSize}" width="${avatarSize}" height="${avatarSize}">
      <circle cx="50%" cy="50%" r="50%" fill="${avatarBackgroundColor}" />
      <text x="50%" y="50%" dominant-baseline="central" text-anchor="middle" font-family="Nunito, Arial, sans-serif" font-size="${
        size || '1rem'
      }" font-weight="500" fill="${textColor}">${names[0][0].toUpperCase()}${names[
    names.length > 1 ? 1 : 0
  ][names.length > 1 ? 0 : 1].toUpperCase()}</text>
    </svg>
  `;

  return `data:image/svg+xml;base64,${btoa(svgContent)}`;
};
