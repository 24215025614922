import { gql } from '@apollo/client';

export const invoiceFieldFragment = gql`
  fragment InvoiceFields on Invoice {
    _id
    invoiceId
    hmo {
      _id
      name
      hmoId
    }
    hmoAmount
    items {
      _id
      currency
      description
      isHMOPayable
      serviceCode
      productCode
      batchNo
      unitCost
      units
      createdFrom
      service {
        id: _id
        isHMOPayable
        description
        serviceCode
      }
      addedBy {
        id: _id
        user {
          firstName
          lastName
        }
      }
    }
    invoiceType
    note
    oopAmount
    patient {
      _id
      user {
        firstName
        lastName
      }
    }
    paymentMethod
    status
    createdAt
    markedBy {
      user {
        firstName
        lastName
      }
    }
    requestedBy {
      user {
        firstName
        lastName
      }
    }
    updatedAt
    markedAt
  }
`;
